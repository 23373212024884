import { Application } from "@hotwired/stimulus"
import { Tabs } from 'tailwindcss-stimulus-components';

const application = Application.start()

// Configure Stimulus development experience
application.register('tabs', Tabs)
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }
